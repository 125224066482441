import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from "gatsby";
import Seo from '../components/SEO/seo';
import Layout from '../components/LayoutPlainText';
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export const _frontmatter = {
  "title": "Flow + Snelstart Partnership",
  "path": "/flow-snelstart-partnership"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`Flow and your accountant working together via SnelStart`}</h1>
    <p>{`A new feature in Flow enables you to share data with your accountant. We do this in collaboration with SnelStart. SnelStart is an online accounting system. `}</p>
    <p>{`If your accountant uses SnelStart as a supplier, your accountant can ask for your permission to share data from your Flow account with him or her through our partner SnelStart. `}</p>
    <p>{`By linking your Flow data to the SnelStart environment of your accountant, your accountant can optimize their services and your Flows. By getting access to your Flow data, your accountant can also share his own flows with you, that you can use. `}</p>
    <p>{`We only share data with your accountant if you give us permission to do so. You can always easily withdraw your permission via the Flow app so that your accountant can no longer view the data in your Flow account. `}</p>
    <p>{`By sharing your Flow data with your accountant, your accountant will have insight into your personal Flows, the name of your account(s), IBAN numbers, details of the contra account, amounts and transaction descriptions in his or her SnelStart environment. `}</p>
    <p>{`Of course, your privacy is important to us. So we only share data with your accountant if you give us permission. You can easily withdraw your permission via the Flow app. Just like Flow, your accountant is an independent data controller and processes your personal data for its own purposes and principles. Your accountant will process your personal data from your Flow account for the purposes described above and on the basis of consent. `}</p>
    <p>{`The privacy statement of your accountant will tell you more about how your accountant handles your personal data, what data he or she processes and for what purposes, but also about your rights and how you can exercise them.`}</p>
    <p>{`As a supplier of an online accounting system, SnelStart is a processor of your accountant. SnelStart has concluded a processor's agreement with all the accountants it works with.`}</p>



    <Seo title={props.pageContext.frontmatter.title} path={props.pageContext.frontmatter.path} mdxType="Seo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      